import { GAME_ORIGIN, GAME_PATH } from '../config';
import { useGameFrameMessages } from '../hooks/useGameFrameMessages';
import { ModalContainer } from './ModalContainer';
import { ModalWrapper } from './ModalWrapper';
import { Preloader } from '../config/Preloader';
import { useKillGameOnSessionEnd } from '../hooks/useKillGameOnSessionEnd';
import { useOrientationHandler } from '../hooks/useOrientationHandler';
import { ScreenRotate } from './ScreenRotate';
import { useApiContext, useGetBonusesTransactions } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { useEffect } from 'react';

export const GameModal = () => {
  const { messagesRef, isGameReady } = useGameFrameMessages();
  const { shouldFrameRender, shouldDisplayScreenRotate } = useOrientationHandler();
  const { isAuthenticated } = useApiContext();
  const { openModal } = useModalContext();

  const { refetch } = useGetBonusesTransactions(
    {
      bonus_type: 'All',
      status: ['Pending']
    },
    { isNeedPages: false, enabled: false }
  );

  useKillGameOnSessionEnd();

  useEffect(() => {
    return () => {
      if (isAuthenticated) {
        (async () => {
          const response = await refetch();
          const transactions = response.data?.data?.bonusTransactions || [];
          if (transactions[0]?.id) {
            openModal('BONUS', { props: { bonusId: transactions[0].id } });
          }
        })();
      }
    };
  }, [isAuthenticated]);

  return (
    <ModalContainer>
      <ModalWrapper
        isGameReady={isGameReady}
        screenRotateImage={shouldDisplayScreenRotate ? <ScreenRotate /> : null}
        preloader={<Preloader />}>
        {shouldFrameRender ? (
          <iframe className="w-full h-full z-[1]" ref={messagesRef} src={GAME_ORIGIN + GAME_PATH} />
        ) : null}
      </ModalWrapper>
    </ModalContainer>
  );
};
